import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import bestTeams from "../images/bestTeams.jpg";
import useStyles from "../styles/styles";

const AboutUs = () => {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={5}>
          <img src={bestTeams} alt="My Team" className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            SLEEPSTAGE
          </Typography>
          <Typography className={classes.aboutUsSubtitle}>
            Introducing SLEEPSTAGE by Squared Inc. - the next stage in sleep
            innovation.
            <br />
            <br />
            We understand that everyone's sleep needs are unique. That's why
            SLEEPSTAGE is designed to adapt to you. While there are other sleep
            products in the market, SLEEPSTAGE stands out with its advanced
            automatic heating technology and sleep tracking AI. Our proprietary
            system ensures that you maintain the optimal temperature for deep,
            restorative sleep throughout the colder nights.
            <br />
            <br />
            Features:
            <br />
            <br />
            Adaptive Heating Technology: Experience a dynamic heating system
            that adjusts in real-time to your body's needs, ensuring you remain
            warm and cozy. Smart Sleep Tracking: Monitor and analyze your sleep
            patterns, helping you understand and improve your sleep habits.
            Ergonomic Design: Crafted for maximum comfort, ensuring that every
            night is a step towards better health. Eco-friendly Materials: We
            care about the planet as much as we care about your sleep.
            SLEEPSTAGE is made using sustainable materials that are both durable
            and kind to the environment.
          </Typography>
          {/* <Button
            variant="contained"
            color="primary"
            sx={{ width: "200px", fontSize: "16px" }}
          >
            CONTACT US
          </Button> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
