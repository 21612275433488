import React from "react";
import { Box, Typography, Link } from "@mui/material";
import useStyles from "../styles/styles";

const Footer = () => {
  const date = new Date().getFullYear();
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.footerContainer}>
      <Box sx={{ width: "600px" }}>
        <Typography className={classes.aboutUsSubtitle} sx={{ fontSize: 12 }}>
          법인명 (상호): 주식회사 스퀘어드홀딩스 | 사업자등록번호: 855-81-02348
          {` `}
          <Link
            href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=8558102348"
            underline="hover"
            sx={{ color: "black", fontWeight: 400 }}
          >
            사업자정보 확인
          </Link>
          <br />
          주소: 부산광역시 해운대구 센텀중앙로 97, 에이동 30층 3008호(재송동,
          센텀스카이비즈) | 대표이사: 기계현
        </Typography>
        {/* <Typography className={classes.footerDate}>
          Open-Source Sample - Buit with MUI
        </Typography> */}
      </Box>
    </Box>
  );
};

export default Footer;
