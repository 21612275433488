import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import myteam from "../images/myteam.jpg";
import useStyles from "../styles/styles";

const Hero = () => {
  const classes = useStyles();

  return (
    <Box className={classes.heroBox}>
      <Grid
        container
        spacing={6}
        className={classes.gridContainer}
        sx={{ mt: "30px" }}
      >
        <Grid item xs={12} md={7}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Squared Inc.
          </Typography>
          <Typography className={classes.aboutUsSubtitle}>
            Welcome to Squared Inc., where innovation meets restful nights.
            Founded with the vision of revolutionizing the sleep healthcare
            industry, we are dedicated to improving the quality of sleep for
            everyone. At Squared Inc., we believe that sleep is not just a
            necessity but an experience that can be optimized for better health,
            productivity, and overall well-being. <br />
            <br /> In today's fast-paced world, the importance of a good night's
            sleep cannot be overstated. Yet, many of us struggle with sleep
            issues, be it due to external factors or internal imbalances. This
            is where we step in. By combining cutting-edge technology with deep
            insights into sleep science, we aim to provide solutions that cater
            to the unique needs of every individual.
          </Typography>
          {/* <Button
            variant="contained"
            color="primary"
            sx={{ width: "200px", fontSize: "16px" }}
          >
            HIRE US
          </Button> */}
        </Grid>
        <Grid item xs={12} md={5}>
          <img src={myteam} alt="My Team" className={classes.largeImage} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
