import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  TextareaAutosize,
  Button,
} from "@mui/material";
import useStyles from "../styles/styles";

const NewAboutUs = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const classes = useStyles();

  const submitForm = (e) => {
    e.preventDefault();
    console.log({ email, firstName, subject, message });
  };

  return (
    <Box className={classes.formContainer}>
      <Typography
        variant="h4"
        className={classes.formHeading}
        sx={{ mb: "30px" }}
      >
        About Us
      </Typography>
      <Typography className={classes.aboutUsSubtitle} align="center">
        At Squared Inc., our journey began with a simple question: How can we
        make sleep better for everyone? This question led us down a path of
        research, innovation, and countless nights of testing. The result?
        SLEEPSTAGE.
        <br />
        <br />
        Our team comprises sleep scientists, engineers, and passionate
        individuals who believe in the transformative power of sleep. We've
        combined our diverse expertise to create a product that doesn't just
        promise better sleep but delivers it.
        <br />
        <br />
        But our mission doesn't stop at creating a great product. We are
        committed to raising awareness about the importance of sleep health,
        supporting research in the field, and continuously innovating to meet
        the evolving needs of our community.
        <br />
        <br />
        Join us on this journey towards better sleep and a healthier tomorrow.
        With SLEEPSTAGE by Squared Inc., the future of sleep is warm and
        inviting.
      </Typography>
    </Box>
  );
};

export default NewAboutUs;
